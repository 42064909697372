<template>
  <div v-if="typeof small==='undefined'" class="fill-height overflow-hidden" :style="'border-top: solid 2px '+event.userSpecPlan.userSpec.spec.color">
    <div class="fill-height full-width position-absolute" :style="'opacity: 0.1;background:'+event.userSpecPlan.userSpec.spec.color"></div>
    <div class="px-2 pb-2 black--text caption">
      <div>{{$tools.time(event.start)}} - {{$tools.time(event.end)}} {{types.filter(v=>v.value===event.userSpecPlan.type)[0].name}}</div>
      <div class="position-absolute">
        <v-avatar class="user" size="32">
          <img :src="event.userSpecPlan.userSpec.user.imageUrl" v-if="event.userSpecPlan.userSpec.user.imageUrl" alt="">
        </v-avatar>
      </div>
      <div class="ml-10 text-overflow font-weight-medium">
        {{event.userSpecPlan.userSpec.user.lastName != null ? event.userSpecPlan.userSpec.user.lastName : ''}}
        {{event.userSpecPlan.userSpec.user.firstName != null ? event.userSpecPlan.userSpec.user.firstName : ''}}
        {{event.userSpecPlan.userSpec.user.secondName != null ? event.userSpecPlan.userSpec.user.secondName : ''}}
      </div>
      <div class="ml-10 mt-n1">
        {{event.userSpecPlan.userSpec.spec.name}}
      </div>
      <div v-if="!$tools.isEmpty(event.userSpecPlan.userSpec.commentAdmin)" class="ml-10">
        <v-icon class="position-absolute" small>mdi-comment-text-outline</v-icon>
        <div class="full-width ml-5" v-html="$tools.nl2br(event.userSpecPlan.userSpec.commentAdmin)"></div>
      </div>
    </div>
  </div>
  <div v-else class="fill-height full-width position-absolute overflow-hidden" :ref="refId" :style="'border-left: solid 2px '+event.userSpecPlan.userSpec.spec.color+';border-top: solid 2px '+event.userSpecPlan.userSpec.spec.color">
    <div class="fill-height full-width position-absolute" :style="'opacity: 0.1;background:'+event.userSpecPlan.userSpec.spec.color"></div>
    <v-menu open-on-hover offset-x :open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <div class="body position-relative pt-1 _specName" v-bind="attrs" v-on="on" :style="{transform:'translate3d(0,'+(eventNewPosition)+'px,0)'}">
          <v-avatar v-if="getVisitPlansForCurrentUser(false).length>0" color="blue" size="8" class="mr-1 mb-1"/>
          <v-avatar v-if="getVisitPlansForCurrentUser(true).length>0" color="pink" size="8" class="mr-1 mb-1"/>
          <v-icon v-if="event.userSpecPlan.type==='workVisitReserve'" size="16" class="mb-1" style="margin-left:-6px">mdi-clock-star-four-points</v-icon>
          <span class="font-weight-medium">
          {{event.userSpecPlan.userSpec.user.lastName != null ? event.userSpecPlan.userSpec.user.lastName : ''}}
          {{event.userSpecPlan.userSpec.user.firstName != null ? event.userSpecPlan.userSpec.user.firstName.slice(0, 1)+'. ' : ''}}
          {{event.userSpecPlan.userSpec.user.secondName != null ? event.userSpecPlan.userSpec.user.secondName.slice(0, 1)+'.' : ''}}
          </span>
           ({{ event.userSpecPlan.userSpec.spec.name}})
        </div>
      </template>
      <v-sheet class="pa-2">
        <div class="v-overlay--absolute">
          <v-avatar class="user" size="38">
            <img :src="event.userSpecPlan.userSpec.user.imageUrl" v-if="event.userSpecPlan.userSpec.user.imageUrl" alt="">
          </v-avatar>
        </div>
        <div class="ml-12">
          {{event.userSpecPlan.userSpec.user.lastName != null ? event.userSpecPlan.userSpec.user.lastName : ''}}
          {{event.userSpecPlan.userSpec.user.firstName != null ? event.userSpecPlan.userSpec.user.firstName : ''}}
          {{event.userSpecPlan.userSpec.user.secondName != null ? event.userSpecPlan.userSpec.user.secondName : ''}}
        </div>
        <div class="ml-12 caption mt-n1">
          {{event.userSpecPlan.userSpec.spec.name}}
        </div>
        <div class="ml-12 caption">
          {{event.userSpecPlan.userSpec.commentAdmin}}
        </div>
        <div class="ml-12 caption">
          Время работы: {{$tools.dateTimeFormat(event.userSpecPlan.begin, 'HH:mm')}} - {{$tools.dateTimeFormat(event.userSpecPlan.end, 'HH:mm')}}
        </div>
        <div class="ml-12 caption">
          Шаг визита: {{event.userSpecPlan.gridMinutes}} минут
        </div>
        <div class="ml-12 caption">
          Тип плана работы: {{event.userSpecPlan.type==='workVisitReserve' ? 'Прием (Резервное время)' : 'Прием'}}
        </div>
        <div class="ml-12 caption" v-if="event.userSpecPlan.userSpec.user.userProfile.comment">
          Комментарий: <span v-html="$tools.nl2br(event.userSpecPlan.userSpec.user.userProfile.comment)"></span>
        </div>
        <v-btn
            :href="`../user/spec/${event.userSpecPlan.userSpec.user.id}/`"
            target="_blank"
            class="ml-12 mt-2 mb-2"
            small depressed
        >Профиль</v-btn>
        <div class="ml-12">
          <v-btn
              @click="$emit('visitPlansForUserSpec', event.userSpecPlan.userSpec)"
              :disabled="getVisitPlansForCurrentUser(false).length===0"
              depressed small class="mb-2">
            Лист ожидания ({{getVisitPlansForCurrentUser(false).length}})
          </v-btn>
          <br/>
          <v-btn
              @click="$emit('visitPlansForUserSpecLiveList', event.userSpecPlan.userSpec)"
              :disabled="getVisitPlansForCurrentUser(true).length===0"
              depressed small>
            Лист живой очереди ({{getVisitPlansForCurrentUser(true).length}})
          </v-btn>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import State from "@/plugins/state";
export default {
  props: ['event','visitPlans','small','scrollTop','zoom','state'],
  data: () => ({
    types:[
      {name:'Прием',value:'workVisit'},
      {name:'Прием (Резервное время)',value:'workVisitReserve'},
      {name:'Рабочее время',value:'work'},
      {name:'Обед',value:'eat'},
      {name:'Перерыв',value:'break'},
    ],
    eventTopPosition:0,
    eventNewPosition:0,
    localState: new State(),
    refId: 0
  }),
  watch:{
    scrollTop(){
      if (this.state.isSuccess&&this.localState.isSuccess){
        this.newPosition();
      }
    },
    zoom(){
      setTimeout(()=>{
        this.load();
      },500);
    },
    state: {
      deep: true,
      handler() {
        if(this.state.isLoading){
          this.eventNewPosition = 0;
        }
        if (this.state.isSuccess){
          console.log('state')
          this.load();
        }
      },
    },
    // event: {
    //   deep: true,
    //   handler() {
    //     console.log(this.event);
    //     if(this.event) {
    //       this.refId = 'refId_'+this.event.userSpecPlan.id;
    //       console.log(this.refId);
    //     }
    //   },
    // }
  },
  created(){
    if(this.event) {
        this.refId = 'refId_'+this.event.userSpecPlan.id;
    }
  },
  methods:{
    load(){
        this.localState.stateLoading();
        this.eventTopPosition = 0;
        this.eventNewPosition = 0;
  

        if(this.$refs[this.refId]){
          this.eventTopPosition = this.getCoordsTop(this.$refs[this.refId]);
        }
        this.localState.stateSuccess();
        // console.log(this.$refs['userSpecPlan'+this.event.userSpecPlan.id])
        // console.log('topPosition '+this.eventTopPosition)
        // console.log('scroll '+this.scrollTop)
        // console.log('load')
    },
    getCoordsTop(elem) {
      return elem.getBoundingClientRect().top + this.scrollTop;
    },
    newPosition() {
      if(this.scrollTop===0) {
        this.eventNewPosition = 0;
      }
      else if(this.scrollTop>this.eventTopPosition){
        this.eventNewPosition = this.scrollTop - this.eventTopPosition + 200;
      } 
      else {
        this.eventNewPosition = 0;
      }
    },
    getVisitPlansForCurrentUser(isLiveList=null){
      let arr = [];
      if(this.event!==null){
        this.visitPlans.data.forEach((v)=>{
          let add = false;

          if(isLiveList!==null){
            if(v.isLiveList===isLiveList){
              if (v.userSpec !== null){
                if (v.userSpec.id === this.event.userSpecPlan.userSpec.id) add = true;
              }
              if (v.spec !== null)
                if (v.spec.id === this.event.userSpecPlan.userSpec.spec.id) add = true;
            }
          } else {
            add = true;
          }

          if(add)
            arr.push(v);
        });
      }
      return arr;
    },
  }
}
</script>

<style lang="scss" scoped>
._specName {
  transition: transform .3s ease-in-out;
  will-change: transform;
}
</style>
