<template>
  <v-row no-gutters>
    <v-col class="navBar border-right">
      <v-sheet :height="height" class="overflow-auto">
        <v-row no-gutters class="mb-2">
          <v-col cols="12">
            <v-row class="px-3 pt-2" no-gutters align="center">
              <v-col><h4 class="font-weight-regular">{{$tools.dateText(daySelected)}}</h4></v-col>
              <v-col cols="auto">
                <v-btn icon @click="setToday">
                  <v-icon v-if="isToday" color="primary" small>mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-date-picker
                v-model="daySelected"
                :show-current="true"
                :first-day-of-week="1"
                no-title
                show-adjacent-months
                color="primary"
                full-width
            /> -->
            <date-picker :daySelected.sync="daySelected" cookieName="userSpecPlansDay"/>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="4" class="px-3">
            <v-btn block depressed color="silver" @click="zoomChange(0)">
              <v-icon :color="zoom===0 ? 'primary' : ''">mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pr-3">
            <v-btn block depressed color="silver" @click="zoomChange(1)">
              <v-icon :color="zoom===1 ? 'primary' : ''">mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pr-3">
            <v-btn block depressed color="silver" @click="zoomChange(2)">
              <v-icon :color="zoom===2 ? 'primary' : ''">mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="px-3 pt-3">
            <v-btn block depressed color="silver" to="/userSpecPlans/">
              Период <v-icon class="ml-2" small> mdi-calendar-expand-horizontal</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="pr-3 pt-3">
            <v-btn block depressed color="silver" @click="copyState.modalOpen(1)">
              Копировать
            </v-btn>
            <dialog-view title="Копирование расписания" :open="copyState.isModalOpen" @cancel="copyState.modalClose()">
              <template v-slot:content>
                <div v-if="copyState.modalData===1">
                  <div class="caption">
                    Выберите промежуток который вы хотите скопировать
                  </div>
                  <v-date-picker
                      v-model="copyState.modalData1"
                      range
                      :show-current="true"
                      :first-day-of-week="1"
                      no-title
                      show-adjacent-months
                      color="primary"
                      full-width
                  />
                </div>
                <div v-if="copyState.modalData===2">
                  <div class="caption">
                    Выберите день с которого необходимо вставить выбранный промежуток
                  </div>
                  <v-date-picker
                      v-model="copyState.modalData2"
                      :show-current="true"
                      :first-day-of-week="1"
                      no-title
                      show-adjacent-months
                      color="primary"
                      full-width
                  />
                </div>
                <div v-if="copyState.modalData===3">
                  <div class="caption mb-3">
                    Выберите филиал
                  </div>
                  <com-branch-autocomplete-multiple :select.sync="copyState.modalData3"/>
                </div>
              </template>
              <template v-slot:actions>
                <v-btn v-if="copyState.modalData===1" @click="copyState.modalData=2" color="primary" text>Далее</v-btn>
                <v-btn v-if="copyState.modalData===2" @click="copyState.modalData=3" color="primary" text>Далее</v-btn>
                <v-btn v-if="copyState.modalData===3&&copyState.modalData3" @click="copy()" color="primary" text>Копировать</v-btn>
              </template>
            </dialog-view>
            <progress-page :loading="copyState.isLoading" icon="mdi-content-copy" dialog/>
          </v-col>
        </v-row>
        <div class="px-3">
          <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>
          <spec-select :specsSelected.sync="specsSelected"/>
          <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="usersSelected"/>
        </div>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet class="calendarSheet" v-if="comRooms.length>0">
        <div class="calendarScroll" :style="{height:height+'px',right:userSpecPlanState.isModalOpen ? '440px' : '0'}">
          <div :style="{height:height-18+'px',width:width+'px'}">
            <v-calendar
                ref="calendar"
                v-model="daySelected"
                color="primary"
                type="category"
                category-show-all
                event-overlap-mode="column"
                :categories="comPlacesShows"
                :events="events"
                :event-color="getEventColor"
                :interval-format="hm"
                :interval-height="zoom===0 ? 25 : zoom===1 ? 40 : 250"
                :interval-minutes="30"
                :interval-count="intervalCountTime"
                :first-time="beginTime"
                category-text="id"
                @mouseenter:time-category="mouseMove"
                @mousemove:time-category="mouseMove"
                @mouseleave:time-category="mouseMove"

                :class="{ narrowColumn: zoom===0 }"
            >
              <template v-slot:category="{ category }" >
                <div v-if="typeof category.id==='undefined'"></div>
                <v-menu v-else open-on-hover bottom offset-y :open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content v-if="category!=null">
                        <v-list-item-title>
                          <div class="position-absolute">
                            <v-avatar :color="category.comRoom.comFloor.comBuilding.comBranch.color" size="14"/>
                          </div>
                          <div class="pl-5">{{category.comRoom.comFloor.comBuilding.comBranch.name}}</div>
                          <v-row no-gutters>
                            <v-col cols="auto" class="title pr-2 font-weight-medium text-overflow">
                              {{category.comRoom.sid}}
                            </v-col>
                            <v-col class="subtitle-2 pt-2 font-weight-medium text-overflow">
                              {{ category.comRoom.name}}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div class="subtitle-2 font-weight-medium text-overflow">{{ category.name}}</div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-sheet v-if="category!=null" class="py-2 px-4">
                    <div class="caption">Рабочее место</div>
                    <div class="subtitle-2">{{category.name}}</div>
                    <div class="caption">Номер кабинета / Название кабинета</div>
                    <div class="subtitle-2">{{category.comRoom.sid}} / {{category.comRoom.name}}</div>
                    <div class="caption">Этаж</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.name}}</div>
                    <div class="caption">Адрес строения</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.comBuilding.street}}</div>
                    <div class="caption">Филиал</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.comBuilding.comBranch.name}}</div>
                  </v-sheet>
                </v-menu>
              </template>
              <template v-slot:event="{ event }">
                <div
                    v-if="typeof event.userSpecPlan!=='undefined'"
                    @click="loadState.isSuccess ? userSpecPlanState.modalOpen(Object.assign({}, event.userSpecPlan, {dayTimeEnd: endTime,dayTimeBegin: beginTime})) : null"
                    @contextmenu.prevent=""
                    @mousedown.right.prevent="loadState.isSuccess ? startDrag(event) : null"
                    @mouseup.right.stop="loadState.isSuccess ? cancelDrag(event) : null"
                    class="fill-height overflow-hidden position-relative"
                >
                  <user-spec-plan-calendar-view :event="event"/>
                </div>
                <div
                    v-if="event.type==='userSpecPlanNew'"
                    @click="userSpecPlanState.modalOpen({
                      begin:$tools.dateTimeFormat(event.start, 'YYYY-MM-DD HH:mm'),
                      end:$tools.dateTimeFormat(event.end, 'YYYY-MM-DD HH:mm', null, 60),
                      type:'workVisit',
                      comPlace:{
                        id:event.comPlace.id,
                        comRoom:{
                          id:event.comPlace.comRoom.id,
                        }
                      },
                      gridMinutes:15,
                      userSpec:{
                        commentAdmin:null,
                      },
                      dayTimeBegin: beginTime,
                      dayTimeEnd: endTime
                    })"
                    class="fill-height">
                  <div class="px-2">{{ $tools.time(event.start) }} Новый план работы</div>
                </div>
              </template>
              <template v-slot:day-body>
                <div v-if="isToday" class="v-current-time" :style="{ top: nowY }"></div>
              </template>
            </v-calendar>
          </div>
        </div>
        <user-spec-plan
            :state="userSpecPlanState"
            :users="users"
            :comRooms="comRooms"
            :events="events"
            @cancel="userSpecPlanState.modalClose()"
            @createdUpdatedDeleted="userSpecPlanState.modalClose();load(true)"
        />
      </v-sheet>
      <v-progress-linear v-if="loadState.isLoading" indeterminate color="primary" style="z-index:10"/>
    </v-col>
  </v-row>
</template>


<script>
import moment from "moment";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import UserSpecPlanCalendarView from "@/views/UserSpec/UserSpecPlanCalendarView";
import UserSpecPlan from "@/views/UserSpec/UserSpecPlan";
import DialogView from "@/components/DialogView";
import ProgressPage from "@/components/ProgressPage";
import DatePicker from "@/components/DatePicker";
import SpecSelect from "@/views/Spec/components/SpecSelect";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";


export default {
  components: {
    ComBranchAutocompleteMultiple,
    ProgressPage, DialogView, UserSpecPlan, UserSpecPlanCalendarView, DatePicker, SpecSelect,UserAutocompleteMultiple},
  data: () => ({
    daySelected: moment(new Date()).format('YYYY-MM-DD'),
    zoom:1,
    height: 600,
    width: 1000,

    loadState: new State(),

    comBranchs:[],
    comBranchsText:null,
    comBranchsSelected:[],

    specs:[],
    specsText:null,
    specsSelected:[],

    users:[],
    usersAllows:[],
    usersSelected:[],

    calendarReady:false,
    comRoomsInitial:[],
    comRooms:[],
    comPlaces:[],
    comPlacesShows:[],
    events:[],
    beginTime:'00:00',
    endTime:'00:00',
    intervalCountTime:0,
    eventDrag:null,
    dayTimeValidation: true,

    userSpecPlanState: new State(),
    copyState: new State(),

  }),
  mounted(){
    this.$cookie.delete('userSpecPlansLink')
    this.$cookie.set('userSpecPlansLink', '/userSpecPlan/', 30);
    // if(this.$store.state.comBranchId) {
    //   this.comBranchsSelected = this.$store.state.comBranchId;
    // } 
    this.load();
    this.resize();
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);
    this.calendarReady = true;
  },
  computed:{
    isToday(){
      return this.daySelected===moment(new Date()).format('YYYY-MM-DD');
    },
    cal(){
      return this.calendarReady ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  watch:{
    daySelected(){
      this.load();
    },
    comBranchsSelected(){
      this.load();
    },
    specsSelected(){
      this.load();
    },
    usersSelected(){
      this.load();
    },
  },
  methods: {
    load(isFullUpdate=false){
      this.loadState.stateLoading();

      axios.all([
        this.comBranchs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comBranch/'),
        this.comRoomsInitial.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comRoom/'),
        this.specs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/spec/'),
        this.users.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/user/?type[]=spec&type[]=admin&onPage=100000')
      ]).then(axios.spread((data1, data2, data3, data4) => {
        //comBranchs
        if(data1!==null) this.comBranchs = data1.data;

        let beginTime = moment('23:59', 'HH:mm');
        let endTime = moment('00:00', 'HH:mm');
        this.comBranchs.forEach((comBranch)=>{
          let index1 = parseInt(moment(this.daySelected, 'YYYY-MM-DD').format('e'));
          comBranch.workPlan.forEach(function(value, index){
            if (index===index1){
              let f = moment(value.begin, 'HH:mm');
              let e = moment(value.end, 'HH:mm');
              if(beginTime.unix()>f.unix()) beginTime = f;
              if(endTime.unix()<e.unix()) endTime = e;
            }
          });
        });
        this.beginTime = beginTime.format('HH:mm');
        this.endTime = endTime.format('HH:mm');
        this.intervalCountTime = moment.duration(moment(this.endTime, 'HH:mm').diff(moment(this.beginTime, 'HH:mm'))).asHours()*2;

        //comRooms
        if(data2!==null) {
          this.comRoomsInitial = data2.data;
        }
        this.comRooms = this.comRoomsInitial
        this.comPlacesShows = [];
        let newComRooms = []
        if(this.comBranchsSelected.length>0){
          
          this.comBranchsSelected.forEach((comBranch)=>{
            this.comRooms.forEach((comRoom)=>{
              if(comRoom.comFloor.comBuilding.comBranch.id==comBranch.id){
                newComRooms.push(comRoom)
              }
            });
          });

          this.comRooms = newComRooms

          this.comRooms.forEach((comRoom)=>{
              comRoom.comPlaces.forEach((comPlace)=>{
                comPlace.comRoom = comRoom;
                this.comPlacesShows.push(comPlace);
              });            
          });
         
          // this.comBranchsSelected.forEach((comBranchId)=>{           
          //   this.comRooms.forEach((comRoom)=>{
          //     if(comRoom.comFloor.comBuilding.comBranch.id===comBranchId){
          //       comRoom.comPlaces.forEach((comPlace)=>{
          //         comPlace.comRoom = comRoom;
          //         this.comPlacesShows.push(comPlace);
          //       });
          //     }
          //   });
          // });
        } else {
          this.comRooms.forEach((comRoom)=>{
            comRoom.comPlaces.forEach((comPlace)=>{
              comPlace.comRoom = comRoom;
              this.comPlacesShows.push(comPlace);
            });
          });
        }

        this.comPlacesShows.sort((a,b)=>{
          let a1 = parseInt(a.comRoom.sid);
          a1 = isNaN(a1) ? 0 : a1;
          a1+=a.name;

          let b1 = parseInt(b.comRoom.sid);
          b1 = isNaN(b1) ? 0 : b1;
          b1+=b.name;

          return a1.localeCompare(b1);
        });

        this.resize();

        //specs
        if(data3!==null) this.specs = data3.data;

        //users
        if(data4!==null) this.users = data4.data.data;
        this.users.forEach(v=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName;
        });
        if(this.specsSelected.length>0){
          this.usersAllows = [];
          this.specsSelected.forEach(specId=>{
            this.users.forEach(user=>{
              user.userSpecs.forEach((userSpec)=>{
                if(userSpec.spec.id===specId)
                  this.usersAllows.push(user);
              });
            });
          });
        } else {
          this.usersAllows = this.users;
        }

        this.userSpecPlansLoad();

      }))
      .catch(e =>{
        Api.responseError(e)
        this.loadState.stateError();
      });
    },

    userSpecPlansLoad(){
      let url = Api.host+'/userSpecPlan/';
      this.comBranchsSelected.forEach((v)=>{
        url +=(url.indexOf('?')===-1 ? '?' : '&')+'comBranch[]='+v.id;
      });
      url+=(url.indexOf('?')===-1 ? '?' : '&')+'begin='+this.daySelected;
      url+=(url.indexOf('?')===-1 ? '?' : '&')+'end='+moment(this.daySelected, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
      axios.get(url)
          .then(r =>{
            this.events = [];
            r.data.forEach((userSpecPlan)=>{
              let show = false;
              if(this.specsSelected.length>0){
                this.specsSelected.forEach((specId)=>{
                  if(userSpecPlan.userSpec.spec.id===specId)
                    show = true;
                });
              } else{
                show = true;
              }

              let show1 = false;
              if(this.usersSelected.length>0){
                this.usersSelected.forEach((user)=>{
                  if(userSpecPlan.userSpec.user.id===user.id)
                    show1 = true;
                });
              } else{
                show1 = true;
              }

              if(show && show1)
                this.events.push({
                  userSpecPlan: userSpecPlan,
                  start: moment(userSpecPlan.begin, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  end: moment(userSpecPlan.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  color: 'rgb(245 245 245)',
                  timed: true,
                  category: userSpecPlan.comPlace.id
                });
            });

            this.loadState.stateSuccess();
          })
          .catch(e =>{
            Api.responseError(e)
            this.loadState.stateError();
          });
    },

    userSpecPlanUpdateTime(event){
      this.loadState.stateLoading();
      axios.put(Api.host+'/userSpecPlan/'+event.userSpecPlan.id+'/', {
        begin:moment(event.start).format('YYYY-MM-DD HH:mm:00'),
        end:moment(event.end).format('YYYY-MM-DD HH:mm:00'),
        comPlace: event.category
      })
      .then((r) =>{
        if(this.userSpecPlanState.isModalOpen)
          this.userSpecPlanState.modalOpen(r.data);
        this.load();
      })
      .catch(e =>{
        this.$store.commit('alertError', e.response.data.message);
        this.load();
      });
    },

    //drag
    startDrag(event){
      this.eventDrag = event;
    },
    mouseMove({ category, date, time}){
      if(this.eventDrag!==null){
        if(typeof category!=='undefined'){
          let t = moment(date+' '+time, 'YYYY-MM-DD HH:mm');

          let diff = moment(this.eventDrag.end).diff(this.eventDrag.start, 'minutes');

          let beginTime = t.minute(t.minute() - (t.minute() % 5)).toDate();
          let endTime = t.clone().add(diff, 'minutes').minute(t.minute() - (t.minute() % 5)).toDate();

          this.eventDrag.category = category.id;
          this.eventDrag.start = beginTime;
          this.eventDrag.end = endTime;
          
          //validation 

          //between event
          this.events.filter(event => event.type != 'userSpecPlanNew').forEach(event =>{
            let category = event.category
            let dragCategory = this.eventDrag.category
            let start = moment(this.$tools.dateTimeFormat(event.start, 'YYYY-MM-DD HH:mm'))
            let end = moment(this.$tools.dateTimeFormat(event.end, 'YYYY-MM-DD HH:mm'))
            let dragStart = moment(this.$tools.dateTimeFormat(this.eventDrag.start, 'YYYY-MM-DD HH:mm'))
            let dragEnd = moment(this.$tools.dateTimeFormat(this.eventDrag.end, 'YYYY-MM-DD HH:mm'))
            let user = event.userSpecPlan.userSpec.id
            let dragUser = this.eventDrag.userSpecPlan.userSpec.id
            if (
              category==dragCategory&&(
              dragStart.isBetween(start,end)||
              dragEnd.isBetween(start,end)||
              start.isBetween(dragStart,dragEnd)||
              end.isBetween(dragStart,dragEnd)||
              dragStart.isSame(start)&&dragEnd.isSame(end)&&user!=dragUser)
            ) {
              this.dayTimeValidation = false
            }
           
          })

          this.userSpecPlanNewRemove();
        }
      } else {
        this.userSpecPlanNew({ category, date, time });
      }
    },
    cancelDrag(event){
      this.validationError()
      if(this.dayTimeValidation) {
        this.userSpecPlanUpdateTime(event);
      }
      this.eventDrag = null;
    },
    validationError() {
      if (this.dayTimeValidation == false) {
        this.load()
        this.$store.commit('alertError', 'Ошибка перемещения, время недоступно');
      }
    },  
    //UserSpecPlan blank
    userSpecPlanNew({ category, date, time }){
      let t = moment(date+' '+time, 'YYYY-MM-DD HH:mm');
      let beginTime = t.clone().minute(t.minute() - (t.minute() % 5)).toDate();
      let endTime = t.clone().minute(t.minute() - (t.minute() % 5)).add(5, 'minutes').toDate();

      let valid = true;
      let startBlock = 0;
      let endBlock = 0;
      this.events.forEach((value)=>{
        if (value.type!=='userSpecPlanBlank'){
          startBlock = value.start.getTime();
          endBlock = value.end.getTime();
          if (value.category===category.id){
            if (beginTime<startBlock && endTime>startBlock)
              valid = false;
            if (beginTime>=startBlock && endTime<=endBlock)
              valid = false;
            if (beginTime<endBlock && endTime>endBlock)
              valid = false;
          }
        }
      });

      if(valid){
        this.userSpecPlanNewRemove();
        this.events.push({
          start: beginTime,
          end: endTime,
          color: 'primary',
          timed: true,
          category: category.id,
          comPlace: category,
          type:'userSpecPlanNew'
        });
      }
    },
    userSpecPlanNewRemove(){
      this.events = this.events.filter(v=>v.type!=='userSpecPlanNew');
    },

    copy(){
      this.copyState.stateLoading();

      let comBranchs = [];
      this.copyState.modalData3.forEach(branch=>{
        comBranchs.push(branch.id);
      })
      axios.post(Api.host+'/userSpecPlan/copy/', {
        fromBegin:this.copyState.modalData1[0],
        fromEnd:this.copyState.modalData1[this.copyState.modalData1.length-1],
        toBegin:this.copyState.modalData2,
        comBranchIds: comBranchs.join(', ')
      })
        .then((r) =>{
          this.$store.commit('alertSuccess', r.data.message);
          this.copyState.modalClose()
          this.copyState.stateSuccess();
          this.copyState.modalData2 = [];
          //this.daySelected = this.copyState.modalData2;
          this.load();
        })
        .catch(e =>{
          this.copyState.stateError(e);
        });
    },

    //calendar tools

    getEventColor(event) {
      return event.color
    },
    hm(interval){
      return interval.time;
    },

    //tools
    resize(){
      this.height = window.innerHeight-64;
      this.width = this.comPlacesShows.length*210;
      this.width += 36;
    },

    zoomChange(zoom){
      this.zoom = zoom;
      let s = document.querySelector('.v-calendar-daily__scroll-area');
      let p = s.scrollTop/(s.children[0].clientHeight/100);

      this.$nextTick(function () {
        s.scrollTo(0, (s.children[0].clientHeight/100)*p);
      })
    },

    setToday () {
      this.daySelected = moment(new Date()).format('YYYY-MM-DD');
      this.load();
    }
  }
}
</script>

<style lang="scss">
.narrowColumn {
  &.v-calendar-category .v-calendar-daily__day,
  &.v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {
      min-width: unset  !important;
      width: 150px !important;
      max-width: 150px  !important;
  }
  .v-calendar-category__columns .v-calendar-category__column-header {
    .v-list-item__title {
      font-size: .7rem !important;
      .v-avatar {
        height: 8px !important;
        min-width: 8px !important;
        width: 8px !important;
      }
      .pl-5 {
        padding-left: 17px !important;
      }
    }
    .title {
      font-size: .9rem !important;
    }
    .subtitle-2 {
      font-size: .7rem !important;
    }
  }
}
</style>